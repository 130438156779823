import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Layout } from "antd";
import { v4 as uuidv4 } from "uuid";
import Logo from "./Logo";
import MenuItems from "./MenuItems";
import HeaderControls from "./HeaderControls";
import SideNav from "./SideNav";

const { Header } = Layout;

const items = [
  {
    label: <Link to="rooms">Rooms</Link>,
    key: uuidv4(),
  },
  {
    label: <Link to="news">News</Link>,
    key: uuidv4(),
  },
  {
    label: <Link to="contacts">Contacts</Link>,
    key: uuidv4(),
  },
  {
    label: <Link to="galleries">Galleries</Link>,
    key: uuidv4(),
  },
  {
    label: "Velit",
    key: uuidv4(),
    children: [
      {
        label: "Culpa",
        key: uuidv4(),
      },
      {
        label: "Voluptate",
        key: uuidv4(),
      },
    ],
  },
];

export default function MainHeader() {
  const [visible, setVisible] = useState(false);
  return (
    <Fragment>
      <div id="main-header">
        <Header>
          <Logo />
          <div id="topnav">
            <MenuItems items={items} />
            <HeaderControls />
            <button
              type="button"
              className={`hamburger ${visible ? "open" : ""}`}
              id="mobile-trigger"
              onClick={() => setVisible(!visible)}
            >
              <span className="hamburger-top"></span>
              <span className="hamburger-middle"></span>
              <span className="hamburger-bottom"></span>
            </button>
          </div>
        </Header>
      </div>
      <Drawer
        title="Qwerty"
        placement="left"
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <SideNav items={items} />
      </Drawer>
    </Fragment>
  );
}
