import React from "react";
import { Button, Carousel, Col, Image, Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { gutter } from "../misc/grid";

const data = [
  { title: "Lorem, ipsum", price: 75, img: "bg3.jpg" },
  { title: "Quis, praesentium", price: 115, img: "bg7.jpg" },
  { title: "Adipisci, quos", price: 90, img: "bg8.jpg" },
];

export default function MainRooms() {
  return (
    <div id="main-rooms">
      <Row gutter={gutter}>
        <Col md={{ span: 24 }} lg={{ span: 16 }}>
          <div className="rooms-slider">
            <Carousel dots={false}>
              {data.map((x) => (
                <Item {...x} />
              ))}
            </Carousel>
          </div>
        </Col>
        <Col md={{ span: 24 }} lg={{ span: 8 }}>
          <div className="rooms-subimage">
            <div
              className="img"
              style={{ background: `url(${require("../img/bg4.jpg")}) no-repeat center center / cover` }}
            ></div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const Item = ({ img, price, title }) => {
  return (
    <div className="room-prev border">
      <div
        className="img"
        style={{ background: `url(${require("../img/" + img)})` }}
      ></div>
      <div className="turkish">
        <div className="room-prev-caption">
          <div className="room-rpev-price">{`$${price} per night`}</div>
          <div className="room-prev-title">
            {title}
            <br />
            room
          </div>
        </div>
      </div>
    </div>
  );
};

const LeftControl = () => {
  return (
    <div className="slider-control left-control">
      <LeftOutlined />
    </div>
  );
};

const RightControl = () => {
  return (
    <div className="slider-control right-control">
      <LeftOutlined />
    </div>
  );
};
