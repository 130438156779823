import React from "react";
import { Col, Image, Row } from "antd";
import { gutter } from "../misc/grid";
import ContactForm from "./ContactForm";
import Contacts from "./Contacts";

export default function MainContact() {
  return (
    <section id="main-contacts">
      <Row gutter={gutter}>
        <Col md={{ span: 24 }} lg={{ span: 8 }}>
          <div className="border">
            <div
              className="img"
              style={{
                background: `url(${require("../img/bg5.jpg")}) no-repeat center center / cover`,
              }}
            ></div>
          </div>
          {/* <Image src={require("../img/bg5.jpg")} preview={false} /> */}
        </Col>
        <Col md={{ span: 24 }} lg={{ span: 8 }}>
          <ContactForm />
        </Col>
        <Col md={{ span: 24 }} lg={{ span: 8 }}>
          <Contacts />
        </Col>
      </Row>
    </section>
  );
}
