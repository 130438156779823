import React from "react";
import { Button, Carousel, Col, Image, Row, Typography } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;

export default function GalleryPrev({ date, id, img, title }) {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
      <div className="gallery-prev">
        <Link to={`/galleries/${id}`}>
          <div className="border">
            <div
              className="img"
              style={{ background: `url(${require("../img/" + img)})` }}
            ></div>
            <div className="turkish">
              <div className="gallery-prev-caption">
                <div className="item-title1">{title}</div>
                <div className="post-date">{date}</div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </Col>
  );
}
