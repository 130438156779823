import React from "react";
import { Image } from "antd";
import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <div className="logo">
      <Link to="/">
        <Image preview={false} src={require('../img/logo.png')} />
      </Link>
    </div>
  );
}
