import React from "react";
import { Typography } from "antd";
import { Parallax } from "react-parallax";

const { Title } = Typography;

export default function SubHeader1() {
  return (
    <div className="sub-header" id="sub-header1">
      <Parallax
        blur={{ min: -5, max: 5 }}
        bgImage={require("../img/sub1.jpg")}
        strength={300}
        // style={{ height: "400px" }}
      >
        <div className="sub-header-caption" id="main-caption">
          <Title>Lorem ipsum</Title>
          <Title level={3}>Incidunt, nemo</Title>
        </div>
      </Parallax>
    </div>
  );
}