import React from "react";
import { Button, Carousel, Col, Image, Row, Typography } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;

export default function PostPrev({ date, excerpt, id, img, text, title }) {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
      <div className="item-prev2">
        <Link to={`/news/${id}`}>
          <div className="border">
            <div
              className="img"
              style={{ background: `url(${require("../img/" + img)})` }}
            ></div>
            <div className="turkish"></div>
          </div>
        </Link>
        <div className="post-date">
          <Text type="secondary">{date}</Text>
        </div>
        <Link to={`/news/${id}`}>
          <div className="item-title1">{title}</div>
        </Link>
        <div className="item-excerpt1">{excerpt}</div>
      </div>
    </Col>
  );
}
