import React, { Fragment } from "react";
import { Avatar, Button, Carousel, Col, Comment, Image, Row } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { gutter } from "../misc/grid";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Subheader2 from "../components/Subheader2";

const dateFormat = "DD.MM.YYYY";

const comments = [
  {
    author: "Lorem, ipsum",
    date: "22.02.2022",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt eos voluptatem tempore fuga alias vel dolorum quas, asperiores animi, perspiciatis quos nobis magnam pariatur vitae.",
  },
  {
    author: "Ut, maxime",
    date: "15.01.2022",
    text: "Labore odit magnam voluptates quas dolorum aspernatur consequuntur nam quam officiis sapiente sequi, illum corrupti magni veniam deleniti perferendis commodi, modi optio, obcaecati dolore. Magnam.",
  },
  {
    author: "Illo, labore",
    date: "03.02.2022",
    text: "Eaque possimus architecto eos eveniet ipsum cupiditate, dolorum explicabo? Ab sed velit iste, doloremque harum nesciunt. Error impedit necessitatibus nobis fuga dolorum. Voluptate, aliquid labore.",
  },
];

export default function Post({ img }) {
  return (
    <Fragment>
      <Subheader2 img="portfolio-title-image.jpg" title="News" />
      <div className="page-content-wrapper">
        <div className="container">
          <div id="post">
            <div className="border">
              <div
                className="img"
                style={{ background: `url(${require("../img/" + img)})` }}
              ></div>
            </div>
            <div className="post-date">22.02.2022</div>
            <div className="post-title">Lorem ipsum dolor</div>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos,
              itaque ad. Placeat mollitia optio doloribus sed dolore.
              Laudantium, doloribus. Suscipit laboriosam explicabo debitis
              veritatis illo dignissimos nam doloremque porro voluptatem, ipsa
              eius repellendus dicta! Asperiores vitae, enim aliquam accusantium
              sunt quidem labore facere provident distinctio incidunt ducimus
              saepe ad ab!
            </p>
            <blockquote>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Inventore nam repellendus, temporibus voluptatum sint eius fugit
              deserunt, cupiditate quibusdam vel officia quo iusto quis magnam.
            </blockquote>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque
              nesciunt commodi odit dolore, pariatur voluptate aperiam tempora
              debitis est molestiae voluptatum sint consequuntur nisi laborum,
              itaque saepe sed laudantium quidem repellat! Explicabo voluptate
              assumenda deserunt, delectus quod voluptates laudantium, odit in
              dignissimos soluta saepe officia. Saepe incidunt minus praesentium
              delectus, ab necessitatibus placeat atque, omnis repudiandae
              voluptatibus sint neque adipisci illum fugiat laborum accusamus,
              velit officiis eaque totam excepturi ex et! Quibusdam sit
              blanditiis id.
            </p>
            <div className="share">
              SHARE:
              <ul>
                <li>
                  <FacebookOutlined />
                </li>
                <li>
                  <TwitterOutlined />
                </li>
                <li>
                  <InstagramOutlined />
                </li>
              </ul>
            </div>
          </div>
          <div id="comments">
            <div className="comments-title">Comments</div>
            {comments.map((x) => (
              <Comment
                author={x.author}
                avatar={
                  <Avatar
                    src="https://joeschmoe.io/api/v1/random"
                    alt={x.author}
                  />
                }
                content={<p>{x.text}</p>}
                datetime={moment(x.date).format(dateFormat)}
              />
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
