import React, { Fragment } from "react";
import { Button, Carousel, Col, Image, Row } from "antd";
import { gutter } from "../misc/grid";
import { v4 as uuidv4 } from 'uuid'
import Subheader2 from "../components/Subheader2";
import GalleryPrev from "../components/GalleryPrev";

const data = [
  {title: "Lorem, ipsum", img: "post1.jpg", date: "15.02.2021", id: uuidv4()},
  {title: "Aspernatur, consequuntur", img: "post2.jpg", date: "26.01.2021", id: uuidv4()},
  {title: "Repellendus, atque", img: "post3.jpg", date: "03.01.2021", id: uuidv4()},
  {title: "Nesciunt, doloribus", img: "post4.jpg", date: "25.10.2020", id: uuidv4()},
  {title: "Sit, praesentium", img: "post5.jpg", date: "15.08.2020", id: uuidv4()},
  {title: "Iure, voluptatibus", img: "post6.jpg", date: "11.08.2020", id: uuidv4()},
];

export default function Galleries() {
  return (
    <Fragment>
      <Subheader2 img="bg8.jpg" title="Galleries" />
      <div className="page-content-wrapper">
        <div id="galleries" className="container">
          <Row gutter={30}>
            {data.map(x=><GalleryPrev {...x} />)}
          </Row>
        </div>
      </div>
    </Fragment>
  )
}
