import React from "react";
import { Carousel, Col, Image, Layout, Row, Typography } from "antd";
import {
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import Newsletter from "./Newsletter";
import Social from "./Social";
import Logo2 from "./Logo2";

export default function Footer() {
  return (
    <Layout.Footer>
      <Logo2 />
      <ul className="footer-addresses">
        <li>
          <PhoneOutlined />
          +1-234-56-789
        </li>
        <li>
          <MailOutlined />
          qwerty@gmail.com
        </li>
        <li>
          <EnvironmentOutlined />
          184 Main Street Victoria 8007
        </li>
      </ul>
      <Newsletter />
      <Social />
    </Layout.Footer>
  );
}
