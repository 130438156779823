import React, { Fragment, useState } from "react";
import { Avatar, Button, Carousel, Col, Comment, Image, Modal, Row } from "antd";
import { gutter } from "../misc/grid";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Subheader2 from "../components/Subheader2";
import GallerySlider from "../components/GallerySlider";

const images = [
  "post1.jpg",
  "post2.jpg",
  "post3.jpg",
  "post4.jpg",
  "post5.jpg",
  "post6.jpg",
];

export default function Gallery() {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  return (
    <Fragment>
      <Subheader2 img="portfolio-title-image.jpg" title="News" />
      <div className="page-content-wrapper">
        <div className="container shutruk-nahhunte">
          <div className="sub-title">Lorem, ipsum</div>
          <div className="title">Unde, inventore</div>
        </div>
      </div>
      <div id="gallery">
        <Row gutter={30}>
          {images.map((img, index) => (
            <GalleryImg
              img={img}
              callBack={() => {
                setData(images.slice(index).concat(images.slice(0, index)));
                setVisible(true);
              }}
            />
          ))}
        </Row>
      </div>
      <Modal
        centered
        destroyOnClose={true}
        title={null}
        footer={null}
        width="100%"
        visible={visible}
        onCancel={() => setVisible(false)}
        wrapClassName="popup-gallery-wrapper"
        bodyStyle={{ padding: "15px" }}
      >
        <GallerySlider data={data} />
      </Modal>
    </Fragment>
  );
}

const GalleryImg = ({ callBack, img }) => {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
      <div className="gallery-img" onClick={() => callBack()}>
        <div className="border">
          <div
            className="img"
            style={{ background: `url(${require("../img/" + img)})` }}
          ></div>
          <div className="turkish"></div>
        </div>
      </div>
    </Col>
  );
};
