import React, { useRef } from "react";
import { Carousel, Col, Image, Row, Typography } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Parallax } from "react-parallax";
import { gutter } from "../misc/grid";

const { Text, Title } = Typography;

const data = [
  {
    name: "Lorem, ipsum",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit aperiam, alias porro illum delectus quibusdam laudantium vitae mollitia magni. Inventore fuga in magni optio, dolores dolore explicabo facilis tempore consectetur.",
  },
  {
    name: "Quis, praesentium",
    text: "Tenetur repudiandae, excepturi earum numquam nesciunt cupiditate dolores nostrum perferendis quis veritatis perspiciatis consequuntur obcaecati reprehenderit quod ut iste corrupti. Maiores atque repellat quod harum dolor molestias aut, praesentium adipisci!",
  },
  {
    name: "Adipisci, quos",
    text: "Hic, eveniet ipsam quaerat cupiditate vero neque quisquam corrupti laborum facilis, quo corporis. Placeat odio non quaerat unde, voluptatem repellat fugiat nostrum et, ipsam odit cumque fugit molestiae eaque eum!",
  },
];

export default function MainFeedback() {
  const refPrev = useRef(null);
  const refNext = useRef(null);
  return (
    <section id="main-feedback">
      <Parallax
        blur={{ min: -5, max: 5 }}
        bgImage={require("../img/bg6.jpg")}
        strength={300}
        // style={{ height: "400px" }}
      >
        <div id="feedback-caption">
          <Row gutter={gutter}>
            <Col span={4}>
              <Image src={require("../img/testimonials-quote-light.png")} />
            </Col>
            <Col span={20}>
              <div className="feedback-data">
                <Carousel
                  dots={false}
                  arrows
                  prevArrow={<div id="feedback-prev" ref={refPrev}></div>}
                  nextArrow={<div id="feedback-next" ref={refNext}></div>}
                >
                  {data.map((x) => (
                    <div className="feedback">
                      <div className="feedback-text">{x.text}</div>
                      <div className="feedback-author">{x.name}</div>
                    </div>
                  ))}
                </Carousel>
                <div className="feedback-controls">
                  <ArrowLeftOutlined onClick={() => refPrev.current.click()} />
                  <ArrowRightOutlined onClick={() => refNext.current.click()} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Parallax>
    </section>
  );
}

// const LeftControl = () => {
//   return (
//     <div className="feedback-slider-control left-control">
//       <ArrowLeftOutlined />
//     </div>
//   );
// };

// const RightControl = () => {
//   return (
//     <div className="feedback-slider-control right-control">
//       <ArrowRightOutlined />
//     </div>
//   );
// };
