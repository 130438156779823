import React from 'react'
import { SearchOutlined }from '@ant-design/icons'

export default function HeaderControls() {
  return (
    <div id="header-controls">
      <SearchOutlined />
      <div className="action-call">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  )
}
