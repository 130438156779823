import React, { Fragment, useRef } from "react";
import { Avatar, Button, Carousel, Col, Comment, Image, Row } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { gutter } from "../misc/grid";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Subheader2 from "../components/Subheader2";
import SmartPanel from "../components/SmartPanel";

const dateFormat = "DD.MM.YYYY";

const data = [
  "post1.jpg",
  "post2.jpg",
  "post3.jpg",
  "post4.jpg",
  "post5.jpg",
  "post6.jpg",
];

export default function Room() {
  const sliderRef = useRef();
  return (
    <div className="paper">
      <Subheader2 img="portfolio-title-image.jpg" title="Lorem ipsum" />
      <div className="wrapper-cover">
        <div id="room">
          <Row gutter={gutter}>
            <Col xl={{ span: 18 }} lg={{ span: 24 }}>
              <div className="room-slider">
                <div className="slider-controls">
                  <div className="prev" onClick={()=>sliderRef.current.prev()}>
                    <LeftControl />
                    <div className="control-text">Prev</div>
                  </div>
                  <div className="next" onClick={()=>sliderRef.current.next()}>
                    <div className="control-text">Next</div>
                    <RightControl />
                  </div>
                </div>
                <Carousel dots={false} ref={sliderRef} arrows>
                  {data &&
                    data.map((img) => (
                      <Fragment>
                        <RoomImg img={img} />
                      </Fragment>
                    ))}
                </Carousel>
              </div>
              <div className="room-gallery">
                {data &&
                  data.map((img, index) => (
                    <RoomImg
                      img={img}
                      callBack={() => {
                        sliderRef.current.goTo(index);
                      }}
                    />
                  ))}
              </div>
              <div className="room-content">
                <div className="title">Lorem, ipsum dolor.</div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Molestias nisi, expedita beatae molestiae quisquam quia eaque
                  ipsa eum libero illum autem, explicabo ipsum voluptatum porro
                  consequuntur aut. Reiciendis consequuntur molestias
                  consectetur earum laborum laudantium animi perferendis natus
                  doloribus eaque, deserunt a voluptatibus voluptatem
                  praesentium alias, possimus id. Ut, doloribus quasi.
                </p>
                <div className="title">Voluptas, iure</div>
                <div className="room-details">
                  <div className="detail">Lorem, ipsum.</div>
                  <div className="detail">Libero, hic?</div>
                  <div className="detail">Mollitia, quidem!</div>
                  <div className="detail">A, nesciunt.</div>
                  <div className="detail">Facilis, maxime.</div>
                  <div className="detail">Nostrum, atque.</div>
                  <div className="detail">Modi, labore?</div>
                  <div className="detail">Ad, repudiandae.</div>
                  <div className="detail">Placeat, nesciunt.</div>
                  <div className="detail">Neque, rerum.</div>
                  <div className="detail">Adipisci, dicta.</div>
                  <div className="detail">Quos, dolorum!</div>
                  <div className="detail">Aliquam, iste.</div>
                </div>
                <div className="title">Blanditiis, qui</div>
                <div className="room-list">
                  <div className="list-item">
                    <p className="list-title">LCD Television</p>
                    <span className="list-value">
                      <span className="marked">+ 54$ </span>to price (per night)
                    </span>
                  </div>
                  <div className="list-item">
                    <p className="list-title">Parking</p>
                    <span className="list-value">
                      <span className="marked">+ 54$ </span>to price (per night)
                    </span>
                  </div>
                  <div className="list-item">
                    <p className="list-title">Pets Allowed</p>
                    <span className="list-value">
                      <span className="marked">+ 54$ </span>to price (per night)
                    </span>
                  </div>
                  <div className="list-item">
                    <p className="list-title">Room No Smoking</p>
                    <span className="list-value">
                      <span className="marked">+ 54$ </span>to price (per night)
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={{ span: 6 }} span={24}>
              <SmartPanel />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

const RoomImg = ({ callBack, img }) => {
  return (
    <div className="room-slide" onClick={() => callBack()}>
      <div
        className="img"
        style={{
          background: `url(${require("../img/" +
            img)}) no-repeat center center / cover`,
        }}
      ></div>
    </div>
  );
};

const LeftControl = () => {
  return (
    <div className="slider-control left-control">
      <ArrowLeftOutlined />
    </div>
  );
};

const RightControl = () => {
  return (
    <div className="slider-control right-control">
      <ArrowRightOutlined />
    </div>
  );
};
