import React from 'react'
import { Pagination } from 'antd';

export default function Paginator() {
  return (
    <div className="pagination">
      <Pagination defaultCurrent={1} total={50} />
    </div>
  )
}
