import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import MainHeader from "./components/MainHeader";
import Up from "./components/Up";
import ContactsRoute from "./routes/ContactsRoute";
import Galleries from "./routes/Galleries";
import Gallery from "./routes/Gallery";
import Main from "./routes/Main";
import News from "./routes/News";
import Post from "./routes/Post";
import Room from "./routes/Room";
import Rooms from "./routes/Rooms";

function App() {
  return (
    <BrowserRouter>
      <MainHeader />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="rooms/:id" element={<Room />} />
        <Route path="rooms" element={<Rooms />} />
        <Route path="contacts" element={<ContactsRoute />} />
        <Route path="news/:id" element={<Post img="bg7.jpg" />} />
        <Route path="news" element={<News />} />
        <Route path="galleries/:id" element={<Gallery img="bg7.jpg" />} />
        <Route path="galleries" element={<Galleries />} />
      </Routes>
      <Footer />
      <Up />
    </BrowserRouter>
  );
}

export default App;
