import React from 'react'
import { PlayCircleOutlined } from '@ant-design/icons'

export default function VideoPrev() {
  return (
    <div className="video-prev">
      <img src={require('../img/bg1.jpg')} alt="" />
      <PlayCircleOutlined />
    </div>
  )
}
