import React from "react";
import { Button, Form, Input, message } from "antd";
import { MailOutlined } from "@ant-design/icons";

export default function Newsletter() {
  return (
    <div id="newsletter">
      <Form layout="inline" onFinish={(values) => {}}>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            icon={<MailOutlined />}
          ></Button>
        </Form.Item>
        <Form.Item
          // label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input placeholder="Subscribe to newsletter..." />
        </Form.Item>
      </Form>
    </div>
  );
}
