import React from "react";
import { Link } from "react-router-dom";
import { Button, Carousel, Col, Image, Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { gutter } from "../misc/grid";

export default function ItemPrev({ excerpt, id, img, price, title }) {
  return (
    <div className="item-prev1">
      <Row gutter={gutter}>
        <Col lg={{ span: 11 }} sm={{ span: 24 }}>
          <div className="border">
            <div
              className="img"
              style={{ background: `url(${require("../img/" + img)})` }}
            ></div>
          </div>
        </Col>
        <Col lg={{ span: 13 }} sm={{ span: 24 }}>
          <div className="item-prev-content1">
            <div className="item-price1">${price}</div>
            <Link to={`/rooms/${id}`}><div className="item-title1">{title}</div></Link>            
            <div className="item-excerpt1">{excerpt}</div>
            <a href="#">
              <div className="more1">Book now</div>
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
}