import React from "react";
import { Typography } from "antd";
import { Parallax } from "react-parallax";

const { Title } = Typography;

export default function Subheader2({ img, title }) {
  return (
    <div className="sub-header" id="sub-header2">
      <Parallax
        blur={{ min: -5, max: 5 }}
        bgImage={require(`../img/${img}`)}
        strength={300}
        // style={{ height: "400px" }}
      >
        <div className="sub-header-caption" id="main-caption2">
          <Title>{title}</Title>
        </div>
      </Parallax>
    </div>
  );
}
