import React, { Fragment } from 'react'
import BookSection from '../components/BookSection'
import MainContact from '../components/MainContact'
import MainFeedback from '../components/MainFeedback'
import MainParallax from '../components/MainParallax'
import MainRooms from '../components/MainRooms'
import SubHeader1 from '../components/SubHeader1'

export default function Main() {
  return (
    <Fragment>
      <SubHeader1 />
      <BookSection />
      <MainParallax />
      <MainRooms />
      <MainContact />
      <MainFeedback />
    </Fragment>
  )
}
