import React from "react";
import { Col, Row } from "antd";
import { gutter } from "../misc/grid";
import Book from "./Book";
import VideoPrev from "./VideoPrev";

export default function BookSection() {
  return (
    <div id="book-section" className="jsx">
      <Row>
        <Col lg={{ span: 24 }} xl={{ span: 8 }}>
          <Book />
        </Col>
        <Col lg={{ span: 24 }} xl={{ span: 16 }}>
          <VideoPrev />
        </Col>
      </Row>
    </div>
  );
}
