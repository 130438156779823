import React from "react";
import { Button, Col, Form, DatePicker, message, Row, Select } from "antd";
import moment from "moment";

const { Option } = Select;
const dateFormat = "DD.MM.YYYY";

export default function SmartPanel() {
  return (
    <div id="smart-panel">
      <div className="title">Lorem, ipsum</div>
      <div className="form1">
        <Form
          // form={form}
          layout="vertical"
          onFinish={(values) => {
            // reset && form.resetFields();
          }}
        >
          <Form.Item name="in" label="Check-in">
            <DatePicker
              size="medium"
              format={dateFormat}
              placeholder="Select"
            />
          </Form.Item>
          <Form.Item name="out" label="Check-out">
            <DatePicker
              size="medium"
              format={dateFormat}
              placeholder="Select"
            />
          </Form.Item>
          <Form.Item name="type" label="Type">
            <Select defaultValue="single">
              <Option value="single">1</Option>
              <Option value="double">2</Option>
              <Option value="twin">3</Option>
            </Select>
          </Form.Item>
          <Form.Item name="rooms" label="Rooms">
            <Select defaultValue="1">
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </Form.Item>
          <Form.Item name="adults" label="Adults">
            <Select defaultValue="0">
              <Option value="0">1</Option>
              <Option value="1">2</Option>
              <Option value="2">3</Option>
            </Select>
          </Form.Item>
          <Form.Item name="children" label="Children">
            <Select defaultValue="0">
              <Option value="0">1</Option>
              <Option value="1">2</Option>
              <Option value="2">3</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <div className="turkish-buttons">
              <Button type="primary" className="xbutton">
                Filter results
              </Button>
              <div className="btn-overlay"></div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
