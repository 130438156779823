import React from 'react'
import { FacebookOutlined, InstagramOutlined, TwitterOutlined, YoutubeOutlined } from "@ant-design/icons";

export default function Social() {
  return (
    <ul className="social">
      <li><a href="#" target="_blank" rel="noopener noreferrer"><FacebookOutlined /></a></li>
      <li><a href="#" target="_blank" rel="noopener noreferrer"><InstagramOutlined /></a></li>
      <li><a href="#" target="_blank" rel="noopener noreferrer"><TwitterOutlined /></a></li>
      <li><a href="#" target="_blank" rel="noopener noreferrer"><YoutubeOutlined /></a></li>
    </ul>
  )
}