import React from "react";
import { Button, Form, Input, message } from "antd";
import { gutter } from "../misc/grid";

const { TextArea } = Input;

export default function ContactForm() {
  return (
    <div className="contact-form">
      <Form onFinish={(values) => {}}>
        <Form.Item
          // label="Name"
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          // label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          // label="Message"
          name="message"
          rules={[{ required: true, message: "Please input your message!" }]}
        >
          <TextArea rows={4} placeholder="Message" />
        </Form.Item>
        <Form.Item>
          <div className="turkish-buttons">
            <Button type="primary" className="xbutton">
              Book
            </Button>
            <div className="btn-overlay"></div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
