import React from 'react'
import { Parallax } from "react-parallax";

export default function MainParallax() {
  return (
    <section id="main-parallax">
      <Parallax
        blur={{ min: -5, max: 5 }}
        bgImage={require("../img/bg2.jpg")}
        strength={300}
        // style={{ height: "400px" }}
      >
      </Parallax>
    </section>
  )
}
