import React, { Fragment } from "react";
import { Button, Carousel, Col, Image, Row } from "antd";
import ContactForm from "../components/ContactForm";
import Contacts from "../components/Contacts";
import Subheader2 from "../components/Subheader2";
import MapContainer from "../components/MapContainer";

export default function ContactsRoute() {
  return (
    <Fragment>
      <Subheader2 img="contact-us-img-1.jpg" title="Contacts" />
      <div className="page-content-wrapper">
        <div className="container">
          <Row gutter={65}>
            <Col xl={{span: 12}} lg={{span: 24}}>
              <ContactForm />
            </Col>
            <Col xl={{span: 12}} lg={{span: 24}}>
              <Contacts />
            </Col>
          </Row>
        </div>
      </div>
      <MapContainer />
    </Fragment>
  );
}
