import React from "react";
import { Button, Col, Form, DatePicker, message, Row, Select } from "antd";
import moment from "moment";

const { Option } = Select;
const dateFormat = "DD.MM.YYYY";

export default function Book() {
  return (
    <div id="book" className="form1">
      <Form
        // form={form}
        layout="vertical"
        onFinish={(values) => {
          // reset && form.resetFields();
        }}
      >
        <div className="book-subtitle">Lorem & Ipsum</div>
        <div className="book-title">Come get some</div>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item name="in" label="Check-in">
              <DatePicker
                size="medium"
                format={dateFormat}
                placeholder="Select"
              />
            </Form.Item>
            <Form.Item name="rooms" label="Rooms">
              <Select defaultValue="1">
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
              </Select>
            </Form.Item>
            <Form.Item name="adults" label="Adults">
              <Select defaultValue="0">
                <Option value="0">1</Option>
                <Option value="1">2</Option>
                <Option value="2">3</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="out" label="Check-out">
              <DatePicker
                size="medium"
                format={dateFormat}
                placeholder="Select"
              />
            </Form.Item>
            <Form.Item name="type" label="Type">
              <Select defaultValue="single">
                <Option value="single">1</Option>
                <Option value="double">2</Option>
                <Option value="twin">3</Option>
              </Select>
            </Form.Item>
            <Form.Item name="children" label="Children">
              <Select defaultValue="0">
                <Option value="0">1</Option>
                <Option value="1">2</Option>
                <Option value="2">3</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <div className="turkish-buttons">
            <Button type="primary" className="xbutton">
              Book
            </Button>
            <div className="btn-overlay"></div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
