import React from "react";
import { Col, Row, Typography } from "antd";
import {
  EnvironmentFilled,
  EnvironmentOutlined,
  MailFilled,
  MailOutlined,
  PhoneFilled,
  PhoneOutlined,
} from "@ant-design/icons";
import { gutter } from "../misc/grid";

const { Text, Title } = Typography;

export default function Contacts() {
  return (
    <div id="contacts">
      <div className="contacts-title">Contact us</div>
      <Text type="secondary">
        Lorem ipsum dolor sit atmet. Proin qual de suis erestopius liqueenean
        sollicituin, lorem quis bibendum auct ornisi elit consequat ipsum, nec
        sagittis sem nibh id elit congit vulputate. Mea at malorum voluptaria.
        Veniam everti recusabo ad.
      </Text>
      <Row gutter={gutter}>
        <Col span={12}>
          <div className="contacts-location">New York</div>
          <ul className="contacts-list">
            <li>
              <PhoneFilled />
              123-45-67-890
            </li>
            <li>
              <MailFilled />
              qwerty@gmail.com
            </li>
            <li>
              <EnvironmentFilled />
              Chechnya, Grozniy, Akhmat 123/1
            </li>
          </ul>
        </Col>
        <Col span={12}>
          <div className="contacts-location">Tokio</div>
          <ul className="contacts-list">
            <li>
              <PhoneFilled />
              123-45-67-890
            </li>
            <li>
              <MailFilled />
              qwerty@gmail.com
            </li>
            <li>
              <EnvironmentFilled />
              Chechnya, Grozniy, Akhmat 123/1
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}
