import React, { Fragment } from "react";
import { Button, Carousel, Col, Image, Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from 'uuid'
import { gutter } from "../misc/grid";
import Subheader2 from "../components/Subheader2";
import ItemPrev from "../components/ItemPrev";
import SmartPanel from "../components/SmartPanel";
import Paginator from "../components/Paginator";

const data = [
  {
    id: uuidv4(),
    title: "Lorem, ipsum",
    price: 75,
    img: "bg3.jpg",
    excerpt:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, magnam similique voluptas esse iusto, perspiciatis odio ipsum sint odit distinctio nesciunt dolor quaerat accusantium praesentium?",
  },
  {
    id: uuidv4(),
    title: "Quis, praesentium",
    price: 115,
    img: "bg7.jpg",
    excerpt:
      "Fugiat deserunt voluptatibus impedit consequatur quia dicta quod suscipit iste? Fugiat cum repudiandae est, magni dolorem facilis, minima unde cumque, sed exercitationem illum modi enim?",
  },
  {
    id: uuidv4(),
    title: "Adipisci, quos",
    price: 90,
    img: "bg8.jpg",
    excerpt:
      "Sequi eos dolor numquam velit recusandae distinctio non similique ipsum accusamus modi soluta illum, tenetur fugiat esse dignissimos doloremque pariatur labore consequatur inventore est veritatis.",
  },
];

export default function Rooms() {
  return (
    <div className="paper">
      <Subheader2 img="portfolio-title-image.jpg" title="Rooms" />
      <div className="wrapper-cover">
        <ul className="sort-menu">
          <li>Lorem.</li>
          <li>Voluptatem!</li>
          <li>Provident.</li>
          <li>Magnam?</li>
          <li>Dolore.</li>
        </ul>
        <div className="items1">
          <Row gutter={gutter}>
            <Col xl={{ span: 18 }} lg={{ span: 24 }}>
              {data.map((x) => (
                <ItemPrev {...x} />
              ))}
            </Col>
            <Col xl={{ span: 6 }} span={24}>
              <SmartPanel />
            </Col>
          </Row>
          <Paginator />
        </div>
      </div>
    </div>
  );
}
