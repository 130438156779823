import React from "react";
import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

export default function GallerySlider({ data }) {
  return (
    <Carousel
      dots={false}
      arrows
      prevArrow={<LeftOutlined />}
      nextArrow={<RightOutlined />}
    >
      {data && data.map((img) => <PhotoSlide img={img} />)}
    </Carousel>
  );
}

const PhotoSlide = ({ img }) => {
  return (
    <div className="photo-slide">
      <div
        className="img"
        style={{ background: `url(${require("../img/" + img)}) no-repeat center center / cover` }}
      ></div>
    </div>
  );
};
