import React from "react";
import { Layout, Menu } from "antd";
import Logo from "./Logo";

export default function SideNav({ items }) {
  return (
    <div id="sidenav">
      <Logo />
      <Menu mode="inline" items={items} />
    </div>
  );
}
