import React, { Fragment } from "react";
import { Button, Carousel, Col, Image, Row } from "antd";
import { gutter } from "../misc/grid";
import { v4 as uuidv4 } from 'uuid'
import Subheader2 from "../components/Subheader2";
import PostPrev from "../components/PostPrev";

const text = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores sit iste voluptatibus incidunt ipsam ea error magnam laboriosam, libero, quod ab iure ex. Doloribus minus aut quis cum voluptatum corporis voluptates provident assumenda! Laudantium consequuntur sit hic ipsum qui rerum nesciunt ut atque beatae tempora esse nemo minima quo exercitationem sint inventore, officiis quasi veritatis numquam ratione ex ducimus enim ea. Quasi voluptas quidem similique perspiciatis explicabo voluptatum fugit possimus debitis, necessitatibus voluptatem nobis ex exercitationem. Facilis corporis explicabo sapiente minus, adipisci perferendis tenetur modi ex. Laboriosam, nesciunt tempore corrupti accusantium nam voluptatum ut soluta at eos autem repellat quaerat.";

const data = [
  {title: "Lorem, ipsum", excerpt: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore sint quasi sapiente harum culpa voluptate recusandae nostrum, expedita voluptatibus aliquid qui dicta sit ut. Eos.", img: "post1.jpg", text: text, date: "15.02.2021", id: uuidv4()},
  {title: "Aspernatur, consequuntur", excerpt: "Eum nulla tempore enim inventore numquam illo maiores itaque architecto a? Natus dolorem debitis non, officia repudiandae consectetur magni. Nostrum corporis alias harum rem consectetur?", img: "post2.jpg", text: text, date: "26.01.2021", id: uuidv4()},
  {title: "Repellendus, atque", excerpt: "Nobis similique a debitis unde ad ea repellat accusamus, tempora temporibus voluptatibus soluta sapiente magnam repellendus dolorum qui repudiandae molestias ullam quo placeat reiciendis provident!", img: "post3.jpg", text: text, date: "03.01.2021", id: uuidv4()},
  {title: "Nesciunt, doloribus", excerpt: "Ipsa illo, tempora doloremque quis magni vitae obcaecati architecto a. Cumque quibusdam labore ut officiis ipsum quos earum corporis laudantium fugiat. Asperiores adipisci quod voluptates!", img: "post4.jpg", text: text, date: "25.10.2020", id: uuidv4()},
  {title: "Sit, praesentium", excerpt: "Illum ipsum quas ut cum, ullam eos deserunt nostrum quasi et sint fugiat officiis delectus expedita a nemo velit eveniet modi consequatur distinctio provident? Provident!", img: "post5.jpg", text: text, date: "15.08.2020", id: uuidv4()},
  {title: "Iure, voluptatibus", excerpt: "Odit alias suscipit quas laborum error quod perferendis! Tenetur temporibus cumque molestias debitis? Totam esse iusto est voluptatum facere fugit neque, non ullam itaque cupiditate.", img: "post6.jpg", text: text, date: "11.08.2020", id: uuidv4()},
];

export default function News() {
  return (
    <Fragment>
      <Subheader2 img="portfolio-title-image.jpg" title="News" />
      <div className="page-content-wrapper">
        <div id="news" className="container">
          <Row gutter={30}>
            {data.map(x=><PostPrev {...x} />)}
          </Row>
        </div>
      </div>
    </Fragment>
  )
}